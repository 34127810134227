<template>
    <div class="payInfo">
        <div class="title">
            <span class="text">费用</span>
            <span class="money">￥{{ money }}</span>
        </div>
        <div class="tips">选择支付方式</div>
        <div class="payList">
            <div class="payLi">
                <div class="payName">
                    <img src="@/assets/rescueImgs/wx_icon.png" alt=""/>
                    <span>微信支付</span>
                </div>
                <van-icon name="checked" color="#357EFE" size="16px" />
            </div>
        </div>
        <div class="payBtn" @click="handPay">支付</div>
        <!-- 检测是否支付成功 -->
        <van-dialog 
            v-model="orderStatus"
            show-cancel-button
            confirm-button-text='已支付'
            confirm-button-color='#357EFE'
            cancel-button-text='重新支付'
            cancel-button-color='#999999'
            @confirm='payStatus'
            @cancel='payStatus'>
            <div class="orderSuccess">
                <span style='font-size: 16px;'>请确认微信支付是否完成</span>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { isWeiXin, isSafari } from '@/utils/index';
import { orderPay, orderPayStatus } from '@/apis/rescue';
export default {
    props:{
		money: '',
        order_id: '',
        pay_no: '',
        orderStatus: false
	},
    methods: {
        // 确认支付
        handPay(){
            console.log(isWeiXin());
            let data = {
                order_id: this.order_id,
                coupon: '',
                money: this.money,
                platform: 'wechat',
                method: 'wap',
                addition: 0,
            }
            orderPay(data).then((res)=>{
                console.log(res,'下单参数')
                if(isWeiXin()){
                    // 微信浏览器
                    // this.JSAPI_PAY();
                    this.$toast('暂未开放');
                }else{
                    // h5支付
                    let url = res.data.data.pay_data;
                    this.pay_no = res.data.data.order_no;
                    this.orderStatus = true;
                    this.WEB_PAY(url);
                }
            })
        },
        JSAPI_PAY(){
            let data = {
                appId: '',
                timeStamp: '',
                nonceStr: '',
                package: '',
                signType: 'MD5',
                paySign: '',
            }
            WeixinJSBridge.invoke('getBrandWCPayRequest',data,function(res){
                WeixinJSBridge.log(res.err_msg);
                if(res.err_msg == "get_brand_wcpay_request:ok"){
                    WeixinJSBridge.log("微信支付成功!");
                }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
                    WeixinJSBridge.log("用户取消支付!");
                }else{
                    WeixinJSBridge.log("支付失败!");
                }
            })
        },
        WEB_PAY(url){
            console.log(url,'支付链接')
            let redirect_url = window.location.href;
            let i = url.indexOf("&redirect_url=");
            let newUrl = url.substring(0, i);
            console.log(newUrl)
            if(isSafari()){
                window.location.href = newUrl + '&redirect_url=' + encodeURIComponent(redirect_url);
            }else{
                window.location.href = newUrl;
            }
        },
        // 已支付
        payStatus(){
            let data = {
                pay_no: this.pay_no
            }
            orderPayStatus(data).then((res)=>{
                console.log(res,'支付状态')
                if(res.data.data.status == 1){
                    // 已支付
                    this.orderStatus = false;
                    this.$emit('paySuccess');
                }else{
                    // 未支付
                    this.orderStatus = false;
                }
            })
        }
    }
}
</script>

<style lang='scss' scoped>
    .payInfo{
        min-height: 200px;
        padding: 23px 15px 30px;
        box-sizing: border-box;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #1F2129;
        .title{
            text-align: center;
            margin-bottom: 24px;
            .text{
                font-size: 14px;
                line-height: 20px;
                color: #647086;
                margin-right: 8px;
            }
            .money{
                font-size: 24px;
                font-weight: bold;
                color: #FF4B13;
                line-height: 32px;
            }
        }
        .tips{
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            color: #141414;
            margin-bottom: 15px;
        }
        .payList{
            .payLi{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .payName{
                    display: flex;
                    align-items: center;
                    color: #141414;
                    font-size: 14px;
                    img{
                        width: 22px;
                        height: 22px;
                        margin-right: 12px;
                    }
                }
            }
        }
        .payBtn{
            margin: 0 auto;
            margin-top: 36px;
            width: 325px;
            height: 46px;
            background: linear-gradient(180deg, #5C9AFE 0%, #357EFE 100%);
            border-radius: 23px;
            text-align: center;
            line-height: 46px;
            font-size: 16px;
            color: #FFFFFF;
        }
        .orderSuccess{
            padding: 27px 0 29px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                width: 38px;
                height: 38px;
                margin-bottom: 10px;
            }
            span{
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 22px;
                color: #191818;
            }
        }
    }
</style>
