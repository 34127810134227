<template>
    <div class="detail" :style="info.is_pay == 1 && info.money != 0 && $statusRule(1, info) ? 'padding-bottom: 78px;' : '' ">
        <div class="orderInfo">
            <div class="title">
                <div class="border"></div>
                <div>下单信息</div>
            </div>
            <div class="li">
                <div class="label">救援类型</div>
                <div class="value">{{ info.type | filterType }}</div>
            </div>
            <div class="li">
                <div class="label">订单编号</div>
                <div class="value">{{ info.no }}</div>
            </div>
            <div class="li">
                <div class="label">案件编号</div>
                <div class="value">{{ info.case_no | filterNull }}</div>
            </div>
            <div class="li" v-if='(info.money != 0 && $statusRule(1, info)) || info.is_pay != 1'>
                <div class="label">支付金额</div>
                <div class="value">
                    <span>{{ info.money }}</span>
                    <span>（{{ info.is_pay == 1 ? '未支付' : '已支付' }}）</span>
                </div>
            </div>
            <div class="li">
                <div class="label">申请救援地</div>
                <div class="value">{{ info.address | filterNull }}</div>
            </div>
            <div class="li" v-if='isDoubleAddress'>
                <div class="label">申请目的地</div>
                <div class="value">{{ info.destination | filterNull }}</div>
            </div>
            <div class="li">
                <div class="label">车主姓名</div>
                <div class="value">{{ info.contact | filterNull }}</div>
            </div>
            <div class="li">
                <div class="label">车主电话</div>
                <div class="value">{{ info.phone | filterNull }}</div>
            </div>
            <div class="li">
                <div class="label">{{ info.type == 11 ? '车架后六位' : '车牌号码' }}</div>
                <div class="value">{{ info.car_no | filterNull }}</div>
            </div>
            <div class="li">
                <div class="label">车架号码</div>
                <div class="value">{{ info.vin | filterNull }}</div>
            </div>
            <div class="li">
                <div class="label">品牌型号</div>
                <div class="value">{{ info.car_model | filterNull }}</div>
            </div>
            <div class="li">
                <div class="label">汽车类型</div>
                <div class="value">{{ info.car_type == 1 ? '轿车' : '货车' }}</div>
            </div>
            <div class="li">
                <div class="label">保单号码</div>
                <div class="value">{{ info.policy_no | filterNull }}</div>
            </div>
            <div class="li">
                <div class="label">预约时间</div>
                <div class="value" v-if='info.is_subscribe == 1'>尽快到达</div>
                <div class="value" v-else>{{ info.appoint_time | filterTime }}</div>
            </div>
            <div class="li" v-if='info.type != 11'>
                <div class="label">是否事故</div>
                <div class="value">{{ info.is_incident_car == 1 ? '是' : '否' }}</div>
            </div>
            <div class="li" v-if='info.is_incident_car == 1'>
                <div class="label">事故号码</div>
                <div class="value">{{ info.accident_no | filterNull }}</div>
            </div>
            <div class="li" v-if='info.is_incident_car == 1'>
                <div class="label">查勘员姓名</div>
                <div class="value">{{ info.checker_name | filterNull }}</div>
            </div>
            <div class="li" v-if='info.is_incident_car == 1'>
                <div class="label">查勘员电话</div>
                <div class="value">{{ info.checker_phone | filterNull }}</div>
            </div>
            <div class="li" v-if='info.type == 1 || info.type == 11'>
                <div class="label">服务项目</div>
                <div class="value">
                    <span v-if='info.science == 1'>车在地库、</span>
                    <span v-if='info.one_two == 1'>一拖二、</span>
                    <span v-if='info.big_vehicle == 1'>五吨板、</span>
                    <span v-if='info.full == 1'>全落地、</span>
                    <span v-if='info.use_wheels == 1'>辅轮</span>
                </div>
            </div>
            <div class="li">
                <div class="label">订单备注</div>
                <div class="value">{{ info.remark | filterNull }}</div>
            </div>
        </div>
        <div class="line"></div>
        <div class="orderInfo" v-if='info.driver_id'>
            <div class="title">
                <div class="border"></div>
                <div>接单信息</div>
            </div>
            <div class="li">
                <div class="label">技师姓名</div>
                <div class="value">{{ info.driver.nick }}</div>
            </div>
            <div class="li">
                <div class="label">技师电话</div>
                <div class="value">{{ info.driver.mobile }}</div>
            </div>
            <div class="li">
                <div class="label">技师车牌</div>
                <div class="value">{{ info.driver.license }}</div>
            </div>
            <div class="li">
                <div class="label">接单时间</div>
                <div class="value">{{ info.depart_time | filterTime }}</div>
            </div>
            <div class="li">
                <div class="label">到达时间</div>
                <div class="value">{{ info.service_time | filterTime }}</div>
            </div>
            <div class="li">
                <div class="label">完成时间</div>
                <div class="value">{{ info.end_time | filterTime }}</div>
            </div>
            <div class="li">
                <div class="label nLabel">出发地到救援地行驶里程</div>
                <div class="value nValue">{{ info.go_distance }}km</div>
            </div>
            <div class="li" v-if='isDoubleAddress'>
                <div class="label nLabel">救援地到目的地行驶里程</div>
                <div class="value nValue">{{ info.get_distance }}km</div>
            </div>
            <div class="li" v-if='isDoubleAddress'>
                <div class="label label_6">客户结算金额</div>
                <div class="value value_6">￥{{ info.money }}</div>
            </div>
            <div class="li">
                <div class="label photoLabel">服务照片  共{{ info.picture.length }}张</div>
                <div class="photoBtn" v-if='info.picture.length !== 0' @click="openPhoto(1)">查看</div>
            </div>
        </div>
        <div class="line"></div>
        <div class="orderInfo">
            <div class="title">
                <div class="border"></div>
                <div>任务详情</div>
            </div>
            <div class="timeLine">
                <div class="timeLi" v-for="(item, index) in info.timeline" :key="index">
                    <div class="time">{{ item.time | filterTime }}</div>
                    <img src="@/assets/rescueImgs/line_icon.png" alt="" class="line_icon"/>
                    <div class="text">{{ item.reject_text }}</div>
                    <div class="border" v-if='index !== info.timeline.length - 1'></div>
                </div>
            </div>
        </div>
        <div class="orderPay" v-if='info.is_pay == 1 && info.money != 0 && $statusRule(1, info)'>
            <div class="payBtn" @click="orderPayShow = true">立即支付</div>
        </div>
        <!-- 订单支付 -->
        <van-popup 
            v-model="orderPayShow" 
            round
            closeable
            position="bottom" 
            safe-area-inset-bottom>
            <div class="payInfo">
                <div class="title">
                    <span class="text">费用</span>
                    <span class="money">￥{{ info.money }}</span>
                </div>
                <div class="tips">选择支付方式</div>
                <div class="payList">
                    <div class="payLi">
                        <div class="payName">
                            <img src="@/assets/rescueImgs/wx_icon.png" alt=""/>
                            <span>微信支付</span>
                        </div>
                        <van-icon name="checked" color="#357EFE" size="16px" />
                    </div>
                </div>
                <div class="payBtn" @click="handPay">支付</div>
            </div>
        </van-popup>
        <!-- 检测是否支付成功 -->
        <van-dialog 
            v-model="orderStatus"
            show-cancel-button
            confirm-button-text='已支付'
            confirm-button-color='#357EFE'
            cancel-button-text='重新支付'
            cancel-button-color='#999999'
            @confirm='payStatus'
            @cancel='payStatus'>
            <div class="orderSuccess">
                <span style='font-size: 16px;'>请确认微信支付是否完成</span>
            </div>
        </van-dialog>
        <!-- 下单成功 -->
        <van-dialog 
            v-model="orderSuccess" 
            confirm-button-text='继续下单'
            confirm-button-color='#357EFE'
            @confirm='continueCreate'>
            <div class="orderSuccess">
                <img src="@/assets/rescueImgs/success.png" alt=""/>
                <span>下单成功</span>
            </div>
        </van-dialog>
		<CustomerService />
    </div>
</template>

<script>
import util from '@/utils';
import { orderDetail } from '@/apis/rescue';
import Data from '@/utils/data';
import PAY from '@/components/rescue/pay';
import CustomerService from '@/components/rescue/customerService';
import { isWeiXin, isSafari } from '@/utils/index';
import { orderPay, orderPayStatus } from '@/apis/rescue';
export default {
    data(){
        return {
            orderId: '',
            info: '',
            pay_no: '',
            orderPayShow: false,
            orderStatus: false,
            orderSuccess: false,
            isDoubleAddress: false
        }
    },
    created(){
        this.orderId = this.$route.query.id;
        console.log(this.orderId)
        this.getInfo();
        console.log('created')
    },
    mounted(){
        console.log('mounted')
    },
    components:{
        PAY,
        CustomerService
    },
    methods: {
        getInfo(){
            let data = {
                order_id: this.orderId
            }
            orderDetail(data).then((res)=>{
                console.log(res,'订单详情')
                if(res.data.code == 50008){
					this.$router.go(-2);
					this.$toast('登录超时！');
					sessionStorage.removeItem('token');
					return
				}
                this.info = res.data.data;
                this.isDoubleAddress = this.$jyTypeData(this.info.type, 'address') == 2
            })
        },
        // 查看服务照片
        openPhoto(type){
            this.$router.push({
                path: '/orderPhoto',
                query: {
                    id: this.orderId,
                    type: type
                }
            })
        },
        // 确认支付
        handPay(){
            console.log(isWeiXin());
            let data = {
                order_id: this.orderId,
                coupon: '',
                money: this.info.money,
                platform: 'wechat',
                method: 'wap',
                addition: 0,
            }
            orderPay(data).then((res)=>{
                // console.log(res,'下单参数')
                if(isWeiXin()){
                    // 微信浏览器
                    // this.JSAPI_PAY();
                    this.$toast('暂未开放');
                }else{
                    // h5支付
                    let url = res.data.data.pay_data;
                    this.pay_no = res.data.data.order_no;
                    this.orderPayShow = false;
                    setTimeout(()=>{
                        this.orderStatus = true;
                    }, 1000)
                    this.WEB_PAY(url);
                }
            })
        },
        JSAPI_PAY(){
            let data = {
                appId: '',
                timeStamp: '',
                nonceStr: '',
                package: '',
                signType: 'MD5',
                paySign: '',
            }
            WeixinJSBridge.invoke('getBrandWCPayRequest',data,function(res){
                WeixinJSBridge.log(res.err_msg);
                if(res.err_msg == "get_brand_wcpay_request:ok"){
                    WeixinJSBridge.log("微信支付成功!");
                }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
                    WeixinJSBridge.log("用户取消支付!");
                }else{
                    WeixinJSBridge.log("支付失败!");
                }
            })
        },
        WEB_PAY(url){
            console.log(url, window.location,'支付链接')
            let redirect_url = window.location.href;
            let i = url.indexOf("&redirect_url=");
            let newUrl = url.substring(0, i);
            console.log(newUrl, redirect_url)
            window.location.href = newUrl;
        },
        // 已支付
        payStatus(){
            let data = {
                pay_no: this.pay_no
            }
            orderPayStatus(data).then((res)=>{
                console.log(res,'支付状态')
                if(res.data.data.status == 1){
                    // 已支付
                    this.orderStatus = false;
                    this.orderSuccess = true;
                    this.getInfo();
                }else{
                    // 未支付
                    this.orderStatus = false;
                }
            })
        },
        continueCreate(){
            this.$router.go(-1)
        }
    },
	filters: {
		filterTime(val){
			if(val){
				return util.formatTime(val)
			}else{
                return '-'
            }
		},
        filterNull(val){
            if(val){
                return val
            }else{
                return '-'
            }
        }
	},
}
</script>

<style lang='scss' scoped>
    .detail{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        .line{
            width: 375px;
            height: 5px;
            background: #FBFBFB;
        }
        .orderInfo{
            padding: 16px 48px 0 16px;
            box-sizing: border-box;
            .title{
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #313037;
                margin-bottom: 10px;
                .border{
                    width: 3px;
                    height: 12px;
                    background: #47A1FA;
                    margin-right: 6px;
                }
            }
            .li{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .label{
                    min-width: 70px;
                    height: 20px;
                    line-height: 20px;
                    color: #707070;
                    margin-right: 6px;
                }
                .value{
                    width: 235px;
                    height: 30px;
                    background: #F9F9F9;
                    opacity: 1;
                    border-radius: 4px;
                    line-height: 30px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    color: #191818;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .nLabel{
                    width: 154px;
                    z-index: 99;
                }
                .nValue{
                    width: 148px;
                    z-index: 99;
                }
                .label_6{
                    width: 100px;
                    z-index: 99;
                }
                .value_6{
                    width: 202px;
                    z-index: 99;
                }
                .photoLabel{
                    min-width: 102px;
                    margin-right: 62px;
                    z-index: 99;
                }
                .photoBtn{
                    width: 64px;
                    height: 28px;
                    background: #FFFFFF;
                    border: 1px solid #357EFE;
                    opacity: 1;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 28px;
                    color: #357EFE;
                }
            }
            .timeLine{
                padding: 16px 0;
                box-sizing: border-box;
                .timeLi{
                    display: flex;
                    position: relative;
                    .time{
                        width: 72px;
                        height: 34px;
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        line-height: 17px;
                        color: #707070;
                        margin-bottom: 20px;
                    }
                    .line_icon{
                        width: 16px;
                        height: 16px;
                        margin: 0 18px 0 11px;
                    }
                    .border{
                        position: absolute;
                        height: calc(100% - 17px);
                        border: 1px solid #C7C7C7;
                        top: 16px;
                        left: 90px;
                    }
                    .text{
                        color: #141414;
                        line-height: 16px;
                    }
                }
            }
        }
        .orderPay{
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 16px 0;
            display: flex;
            justify-content: center;
            background: #fff;
            z-index: 100;
            .payBtn{
                width: 342px;
                height: 46px;
                background: linear-gradient(180deg, #5C9AFE 0%, #357EFE 100%);
                opacity: 1;
                border-radius: 6px;
                text-align: center;
                line-height: 46px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
        .payInfo{
            min-height: 200px;
            padding: 23px 15px 30px;
            box-sizing: border-box;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #1F2129;
            .title{
                text-align: center;
                margin-bottom: 24px;
                .text{
                    font-size: 14px;
                    line-height: 20px;
                    color: #647086;
                    margin-right: 8px;
                }
                .money{
                    font-size: 24px;
                    font-weight: bold;
                    color: #FF4B13;
                    line-height: 32px;
                }
            }
            .tips{
                font-size: 14px;
                font-weight: bold;
                line-height: 20px;
                color: #141414;
                margin-bottom: 15px;
            }
            .payList{
                .payLi{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .payName{
                        display: flex;
                        align-items: center;
                        color: #141414;
                        font-size: 14px;
                        img{
                            width: 22px;
                            height: 22px;
                            margin-right: 12px;
                        }
                    }
                }
            }
            .payBtn{
                margin: 0 auto;
                margin-top: 36px;
                width: 325px;
                height: 46px;
                background: linear-gradient(180deg, #5C9AFE 0%, #357EFE 100%);
                border-radius: 23px;
                text-align: center;
                line-height: 46px;
                font-size: 16px;
                color: #FFFFFF;
            }
        }
        .orderSuccess{
            padding: 27px 0 29px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                width: 38px;
                height: 38px;
                margin-bottom: 10px;
            }
            span{
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 22px;
                color: #191818;
            }
        }
    }
</style>
